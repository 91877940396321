
<script>
  import View from '@/views/View'
  import LoadSections from '@/mixins/load-sections'
  import FetchPage from '@/mixins/fetchPage'
  const HOME = '1JmgkHGgx5xjyD32PiPTrU'
  export const STORE_NAMESPACE_HOME = 'page/home'

  export default {
    name: 'Home',

    metaInfo: { title: 'Strona Główna' },

    extends: View,

    mixins: [
      LoadSections([
        'home-hero',
        // 'home-description',
        // 'home-features',
        'home-map',
        'contact-us',
        'trusted-us',
        'footer',
      ]),
      FetchPage(HOME, STORE_NAMESPACE_HOME),
    ],
    props: {
      id: {
        type: String,
        default: 'home',
      },
    },
  }
</script>
